import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import api from 'api/';
import './index.scss';
import App from './App';
import renderApp from './renderApp';
import * as serviceWorker from './serviceWorker';
import { getItem } from 'storage/persistent';

renderApp(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}

const token = getItem('accessToken');
if (token) {
  api.authorize(token);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
