const NS = 'SETTINGS/OKVEDS/';

export const _PENDING = '_PENDING';
export const _FULFILLED = '_FULFILLED';
export const _REJECTED = '_REJECTED';

export const INVALIDATE = NS + 'INVALIDATE';
export const FETCH = NS + 'FETCH';
export const ADD = NS + 'ADD';
export const UPDATE = NS + 'UPDATE';
export const DELETE = NS + 'DELETE';
