import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import debugMiddlewares from '_libs/redux-debug-middwares';
import createReducers from './reducers/';
import { saveStore } from './storage/persistent';
import { AppStateStore } from './storage/store';

export const history = createBrowserHistory();

export default () => {
  const reducer = createReducers(history);
  const store: AppStateStore = createStore(
    reducer,
    undefined,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        promiseMiddleware,
        routerMiddleware(history),
        ...debugMiddlewares,
      ),
    ),
  );

  store.subscribe(() => {
    saveStore(store);
  });

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(reducer);
      });
    }
  }

  return store;
};
