import React from 'react';
import loadable from 'react-loadable';
import LoadingStub from './LoadingStub';

/**
 * Wrap a module with react-loadable
 *
 * @param dynamicImport `import('./module')`
 * @param [loadableOptions] Options to `react-loadable`
 * @return
 * @see https://github.com/jamiebuilds/react-loadable#loadable-and-loadablemap-options
 */
export default function loadableFactory<P>(
  // FIXME: dynamic `import('./file')` has incompatible type `typeof import('./file')` which cannot be properly declared of change
  // add `... | any` to workaround with wrong typing for dynamic import
  dynamicImport: Promise<React.ComponentType<P>> | any,
  loadableOptions = {},
): React.ComponentType<P> & loadable.LoadableComponent {
  return loadable({
    loader: () => dynamicImport,
    ...loadableOptions,
    loading: LoadingStub,
  });
}
