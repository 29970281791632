import { Set as ImmutableSet } from 'immutable';
import filterObject from '_libs/object/filter';
import {
  APPROVE_FULFILLED,
  APPROVE_PENDING,
  APPROVE_REJECTED,
  CHECKED,
  FETCH_FULFILLED,
  FETCH_PENDING,
  FETCH_REJECTED,
  FILTER_CHANGE,
  FILTER_RESET,
  FREE,
  INVALIDATE,
  ItemsActionType,
  UPLOAD_FULFILLED,
  UPLOAD_PENDING,
  UPLOAD_REJECTED,
} from 'actions/import-statement/items/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';
import { compareItems } from 'api/import/statement';
import {
  ImportRow,
  ImportRowId,
  ItemsFilterParams,
} from 'types/api/import/statement/row';
import illFactory, {
  State as illState,
} from 'components/common/infinite-load-list/state-tools';

export interface State extends illState<ImportRow, 'id'> {
  checked: ImmutableSet<ImportRowId>;
  filter: ItemsFilterParams;
  isFetching: boolean;
  didFetched: boolean;
  isFetchFailed: boolean;
  didInvalidate: boolean;
  sendingId: ImportRowId | null;
  isSending: boolean;
  isSendFailed: boolean;
}

const illTools = illFactory(compareItems, 'id');

const initialState: State = {
  ...illTools.initialState,
  checked: ImmutableSet(),
  filter: {
    date_start: '',
    date_end: '',
  },
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
  sendingId: null,
  isSending: false,
  isSendFailed: false,
};

export default (state = initialState, action: ItemsActionType): State => {
  switch (action.type) {
    case COMPANY_UNSELECT_UUID:
    case FREE:
      return {
        ...initialState,
        filter: state.filter,
      };

    case INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
        nextWanted: 0,
      };

    case FILTER_RESET:
      return initialState;

    case FILTER_CHANGE:
      return {
        ...initialState,
        filter: {
          ...state.filter,
          ...filterObject(action.payload, (k, v) => v !== undefined),
        },
      };

    case FETCH_PENDING:
    case UPLOAD_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_FULFILLED: {
      if (!state.isFetching) {
        return state;
      }

      return {
        ...state,
        ...illTools.addStateItems(state, action.payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case FETCH_REJECTED:
    case UPLOAD_REJECTED: {
      if (!state.isFetching) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case CHECKED: {
      const { id, checked: on } = action.payload;
      const { checked, itemsMap } = state;
      return {
        ...state,
        checked: on
          ? checked.remove(id)
          : itemsMap.has(id)
          ? checked.add(id)
          : checked,
      };
    }

    case UPLOAD_FULFILLED: {
      if (!state.isFetching) {
        return state;
      }

      return {
        ...state,
        ...illTools.injectStateItems(state, action.payload.items),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case APPROVE_PENDING: {
      const { id } = action.meta;
      return {
        ...state,
        sendingId: id,
        isSending: true,
      };
    }

    case APPROVE_FULFILLED: {
      const { id, update } = action.meta;
      const item = state.itemsMap.get(id);
      return {
        ...state,
        ...(item
          ? illTools.injectStateItems(state, [{ ...item, ...update }], true)
          : {}),
        sendingId: null,
        isSending: false,
        isSendFailed: false,
      };
    }

    case APPROVE_REJECTED:
      return {
        ...state,
        sendingId: null,
        isSending: false,
        isSendFailed: true,
      };

    default:
      return state;
  }
};
