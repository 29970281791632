export const _PENDING = '_PENDING';
export const _FULFILLED = '_FULFILLED';
export const _REJECTED = '_REJECTED';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INVALIDATE = 'AUTH_INVALIDATE';
export const AUTH_FETCH = 'AUTH_FETCH';
export const AUTH_AVATAR_UPLOAD = 'AUTH_AVATAR_UPLOAD';
export const AUTH_EDIT_USER_INFO = 'AUTH_EDIT_USER_INFO';

/** @deprecated Use `AUTH_FETCH + _PENDING` */
export const AUTH_FETCH_PENDING = 'AUTH_FETCH_PENDING';
/** @deprecated Use `AUTH_FETCH + _FULFILLED` */
export const AUTH_FETCH_FULFILLED = 'AUTH_FETCH_FULFILLED';
/** @deprecated Use `AUTH_FETCH + _REJECTED` */
export const AUTH_FETCH_REJECTED = 'AUTH_FETCH_REJECTED';
