import {
  _FULFILLED,
  _PENDING,
  _REJECTED,
  AUTH_AVATAR_UPLOAD,
  AUTH_EDIT_USER_INFO,
  AUTH_FETCH,
  AUTH_INVALIDATE,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
} from 'actions/auth/types';
import { makeInitialLoader } from 'storage/persistent';

const emptyState = {
  isAuthenticated: false,
  accessToken: null,
  accessTokenExpires: null,
  userInfo: null,
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

const makeInitialState = makeInitialLoader(emptyState, ({ auth }) => auth);

export default (state = makeInitialState(), action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case AUTH_SET_TOKEN: {
      const { access_token, access_token_exp } = payload;

      if (state.accessToken === access_token) {
        return state;
      }

      return {
        ...state,
        accessToken: access_token,
        accessTokenExpires: access_token_exp,
        didInvalidate: true,
        ...(access_token ? {} : { userInfo: null }),
      };
    }

    case AUTH_LOGOUT:
      return emptyState;

    case AUTH_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case AUTH_FETCH + _PENDING:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case AUTH_FETCH + _FULFILLED:
      return {
        ...state,
        userInfo: payload,
        isAuthenticated: true,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };

    case AUTH_FETCH + _REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    case AUTH_AVATAR_UPLOAD + _PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case AUTH_AVATAR_UPLOAD + _FULFILLED: {
      const { url } = payload;
      const { userInfo } = state;

      return {
        ...state,
        userInfo: userInfo
          ? {
              ...userInfo,
              avatar: url,
            }
          : null,
        didInvalidate: !userInfo,
        isFetching: false,
        isFetchFailed: false,
      };
    }

    case AUTH_AVATAR_UPLOAD + _REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    case AUTH_EDIT_USER_INFO + _FULFILLED: {
      const { userInfo } = meta;
      return {
        ...state,
        userInfo: { ...state.userInfo, ...userInfo },
        isFetching: false,
      };
    }

    default:
      return state;
  }
};
