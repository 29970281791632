import * as types from 'actions/users/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  usersList: [],
  usersMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    usersList: list,
    usersMap: new Map(list.map(user => [user.id, user])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case types.USERS_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case types.USERS_FETCH + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.USERS_FETCH + types.FULFILLED: {
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.USERS_FETCH + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.USER_CREATE + types.FULFILLED: {
      const { user } = payload;

      const usersList = state.usersList.slice();
      usersList.push(user);
      const usersMap = new Map(state.usersMap);
      usersMap.set(user.id, user);

      return {
        ...state,
        usersList,
        usersMap,
        isFetching: false,
      };
    }

    case types.USER_CREATE + types.PENDING:
    case types.USER_DELETE + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }
    case types.USER_CREATE + types.REJECTED:
    case types.USER_DELETE + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.USER_DELETE + types.FULFILLED: {
      const { userId } = payload;
      const usersList = state.usersList.filter(
        curUser => curUser.id !== userId,
      );
      const usersMap = new Map(state.usersMap);
      usersMap.delete(userId);
      return {
        ...state,
        usersList,
        usersMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};
