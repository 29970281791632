import * as types from 'actions/docs/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  docsList: [],
  docsMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    docsList: list,
    docsMap: new Map(list.map(doc => [doc.id, doc])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case types.DOCS_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case types.DOCS_FETCH + types.PENDING:
    case types.DOC_DELETE + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.DOCS_FETCH + types.FULFILLED: {
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.DOCS_FETCH + types.REJECTED:
    case types.DOC_DELETE + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.DOC_EDIT: {
      const { docInfo } = payload;
      const newDoc = { ...state.docsMap.get(docInfo.id), ...docInfo };

      const docsList = state.docsList.map(oldDoc =>
        oldDoc.id === docInfo.id ? newDoc : oldDoc,
      );
      const docsMap = new Map(state.docsMap);
      docsMap.set(newDoc.id, newDoc);

      return {
        ...state,
        docsList,
        docsMap,
      };
    }
    case types.DOC_ADD: {
      const { docInfo } = payload;
      const newDoc = { ...state.docsMap.get(docInfo.id), ...docInfo };

      const docsList = state.docsList.map(oldDoc =>
        oldDoc.id === docInfo.id ? newDoc : oldDoc,
      );
      const docsMap = new Map(state.docsMap);
      docsMap.set(newDoc.id, newDoc);

      return {
        ...state,
        docsList,
        docsMap,
      };
    }

    case types.DOC_DELETE + types.FULFILLED: {
      const { docId } = payload;
      const docsList = state.docsList.filter(doc => doc.id !== docId);
      const docsMap = new Map(state.docsMap);
      docsMap.delete(docId);
      return {
        ...state,
        docsList: docsList,
        docsMap: docsMap,
        isFetching: false,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};
