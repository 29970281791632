import * as types from 'actions/settings/accounts/types';

const initialState = {
  showDialog: false,
  showBranchDialog: false,
  bank: null,
  branch: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SHOW_DIALOG:
      return {
        ...state,
        showDialog: payload,
      };
    case types.SHOW_BRANCH_DIALOG:
      return {
        ...state,
        showBranchDialog: payload,
      };
    case types.SELECT_BANK: {
      const { bank } = payload;
      return {
        ...state,
        bank,
      };
    }
    case types.SELECT_BRANCH: {
      const { branch } = payload;
      return {
        ...state,
        branch,
      };
    }
    default:
      return state;
  }
};
