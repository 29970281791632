import { Map } from 'immutable';

export interface State<T, K extends keyof T> {
  itemsList: T[];
  itemsMap: Map<T[K], T>;
  /**
   * Offset for next item to fetch
   */
  nextWanted: number;
  /**
   * Latest in terms of pagination
   */
  latestItem: T | null;
  /**
   * If there are more items may be fetched
   *
   * This is inverse of "if we reached the end of list"
   */
  mayHaveMore: boolean;
  /**
   * Items per page to load in each request
   */
  pageSize: number;
}

type CompareFunction<T> = (a: T, b: T) => number;

export default function createStateTools<T, K extends keyof T>(
  compareItems: CompareFunction<T>,
  key: K,
) {
  type KT = T[K];
  type S = State<T, K>;

  const updateLatestItem = (prev: T | null, next: T): T | null =>
    !prev || compareItems(prev, next) < 0 ? next : prev;

  function mergeStateItems(
    state: S,
    list: T[],
    replace = false,
  ): Pick<S, 'itemsList' | 'itemsMap'> {
    let itemsList = [...state.itemsList];
    let { itemsMap } = state;

    list.forEach(item => {
      const id = item[key];
      if (itemsMap.has(id)) {
        if (!replace) {
          return;
        }
        itemsList = itemsList.map(it => (it[key] === id ? item : it));
      } else {
        itemsList.push(item);
      }
      itemsMap = itemsMap.set(id, item);
    });

    return {
      itemsList,
      itemsMap,
    };
  }

  return {
    initialState: {
      itemsList: [],
      itemsMap: Map<KT, T>(),
      nextWanted: 0,
      latestItem: null,
      mayHaveMore: true,
      pageSize: 20,
    } as S,

    addStateItems(
      state: S,
      list: T[],
    ): Pick<
      S,
      'itemsList' | 'itemsMap' | 'nextWanted' | 'latestItem' | 'mayHaveMore'
    > {
      const { itemsList, itemsMap } = mergeStateItems(state, list);
      const { latestItem } = state;

      return {
        itemsList,
        itemsMap,
        nextWanted: itemsList.length,
        latestItem: list.reduce(updateLatestItem, latestItem),
        mayHaveMore: list.length === state.pageSize,
      };
    },

    injectStateItems(
      state: S,
      list: T[],
      replace = false,
    ): Pick<S, 'itemsList' | 'itemsMap' | 'nextWanted'> {
      const { itemsList, itemsMap } = mergeStateItems(state, list, replace);
      const { nextWanted, latestItem } = state;

      let injected = 0;
      if (latestItem) {
        list.forEach(item => {
          if (compareItems(item, latestItem) < 0) {
            injected++;
          }
        });
      }

      return {
        itemsList,
        itemsMap,
        nextWanted: nextWanted + injected,
      };
    },
  };
}
