import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Promise from 'promise';

const BASE = 'https://api.cubux.ru';

const instances: AxiosInstance[] = [];

function create(config?: AxiosRequestConfig): AxiosInstance {
  const i = axios.create({
    baseURL: BASE,
    ...config,
  });
  instances.push(i);
  return i;
}

const instance = create();

export default instance;

export const dataOnly = create();
dataOnly.interceptors.response.use(response => {
  if (response.data) {
    return response.data;
  }
  return Promise.reject(response);
});

export function authorize(accessToken: string) {
  instances.forEach(instance => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  });
}

export function unauthorize() {
  instances.forEach(instance => {
    delete instance.defaults.headers.common['Authorization'];
  });
}
