import equal from 'fast-deep-equal';
import { AppState } from '../reducers/';
import { AppStateStore } from './store';

const LOCAL_STORAGE_KEY = 'app-state-persistent.v1';

type StoredData = {
  accessToken?: string;
  accessTokenExpires?: number;
  refreshToken?: string;
  refreshTokenExpires?: number;
  customizer?: { [p: string]: any };
  // [p: string]: any;
};

type PartialState = { [K in keyof AppState]?: Partial<AppState[K]> };

let stored: StoredData | null = null;

function wantStore(state: AppState): StoredData {
  const {
    auth: { accessToken, accessTokenExpires },
    authRefresh: { refreshToken, refreshTokenExpires },
    customizer,
  } = state;
  // Persistent props mapping.
  // Use `undefined` value to remove a property from persistent storage.
  return {
    accessToken: accessToken || undefined,
    accessTokenExpires: accessTokenExpires || undefined,
    refreshToken: refreshToken || undefined,
    refreshTokenExpires: refreshTokenExpires || undefined,
    customizer,
  };
}

function extractStored(stored: StoredData): PartialState {
  const {
    accessToken,
    accessTokenExpires,
    refreshToken,
    refreshTokenExpires,
    customizer,
  } = stored;

  return {
    auth: accessToken
      ? {
          accessToken,
          accessTokenExpires,
          isAuthenticated: true,
        }
      : {},
    authRefresh: refreshToken
      ? {
          refreshToken,
          refreshTokenExpires,
        }
      : {},
    customizer: customizer || {},
  };
}

function putToStorage(stored: StoredData): void {
  const json = JSON.stringify(stored);
  // Lazy check if the object is empty
  if ('{}' === json) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY, json);
  }
}

const loadFromStorage = () => {
  let stored: StoredData = {};

  try {
    const json = localStorage.getItem(LOCAL_STORAGE_KEY);
    // Lazy forward check if the JSON contains an Object.
    // If so and the JSON will be parsed successfully,
    // then the value is an Object.
    if (json && /^{/.test(json)) {
      stored = JSON.parse(json);
    }
  } catch (e) {}

  return stored;
};

const getStored = () => {
  if (null === stored) {
    stored = loadFromStorage();
  }

  return stored;
};

export const saveStore = (store: AppStateStore) => {
  const newStored = wantStore(store.getState());

  if (!equal(newStored, stored)) {
    putToStorage(newStored);
    stored = newStored;
  }
};

/**
 * Makes function to build initialState
 *
 * ```
 * // inner reducer for `store.getState().foo`
 * const emptyState = {
 *   bar: null,
 * };
 *
 * const makeInitialState = makeInitialLoader(emptyState, ({ foo }) => foo);
 *
 * export default (state = makeInitialState(), action) => {
 *   ...
 * };
 * ```
 *
 * @param emptyState Empty initial state
 * @param stateSlicer `(state) => state` A slicer to get appropriate part of storage
 * @return A function to create initialState fulfilled from persistent storage. Use the function call as default value for `state` in reducer.
 */
export const makeInitialLoader = <S = {}>(
  emptyState: S,
  stateSlicer: (state: PartialState) => Partial<S> | null | undefined,
): (() => S) => {
  return () => ({
    ...emptyState,
    ...stateSlicer(extractStored(getStored())),
  });
};

export const getItem = <K extends keyof StoredData>(item: K): StoredData[K] => {
  return getStored()[item];
};
