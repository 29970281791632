import { dataOnly } from '../instance';
import { CompanyEvent } from 'types/api/company/events';

const e = encodeURIComponent;

type ListParams = {
  year?: number;
  month?: number;
  limit?: number;
  start?: number;
};

export default {
  list: (uuid: string, params?: ListParams) =>
    dataOnly.get<any, CompanyEvent[]>(`/company/events/${e(uuid)}`, {
      params,
    }),
};

export const compareItems = (a: CompanyEvent, b: CompanyEvent): number =>
  b.date_start < a.date_start
    ? -1
    : b.date_start > a.date_start
    ? 1
    : a.id - b.id;
