import { Map as MapImmutable } from 'immutable';
import toIsoDate from '_libs/date/to-iso-date';
import {
  RESET,
  FORM_CHANGE,
  STEP_BACK,
  STEP_NEXT,
  STEP_TO,
  ORG_FETCH,
  BANK_DIALOG_SHOW,
  BRANCH_DIALOG_SHOW,
  ACCOUNTS_CREATE,
  ACCOUNTS_DELETE,
  ACCOUNTS_EDIT,
  ACCOUNTS_EDIT_DONE,
  ACCOUNTS_FIELD_CHANGE,
  CASHES_CREATE,
  CASHES_DELETE,
  CASHES_EDIT,
  CASHES_EDIT_DONE,
  CASHES_FIELD_CHANGE,
  COMPANY_SEND,
  ACCOUNT_SEND,
  CASH_SEND,
  _FULFILLED,
  _PENDING,
  _REJECTED,
} from 'actions/company-add/types';
import * as AccType from 'constants/account-types';
import convertFromSuggestion from 'components/dadata/address/select/convert-from-suggestion';

/**
 * @typedef {number} LocalIndex
 */

const _STEP_LAST = 2;

const allowedSteps = step => ({
  canStepBack: step > 0,
  canStepNext: step < _STEP_LAST,
});

const accountFieldsFromBranch = (bank, branch) => ({
  _bankInfo: bank,
  _branchInfo: branch,
  bik: branch.bik,
  bank_id: bank.id,
  korr_acct_num: branch.korschet,
});

/**
 * @type {function(): LocalIndex}
 */
const generateId = (() => {
  /**
   * @type {LocalIndex}
   */
  let id = 0;

  return () => ++id;
})();

const createAccount = (() => {
  /**
   * @param {Object} bank
   * @param {Object} branch
   * @return {Object}
   */
  return (bank, branch) => ({
    _id: generateId(),
    _isNew: true,
    ...accountFieldsFromBranch(bank, branch),
    name: bank.name,
    acct_num: '',
    money: 0,
    acct_type: AccType.DEFAULT,
  });
})();

const createCash = (() => {
  return () => ({
    _id: generateId(),
    _isNew: true,
    name: '',
    money: 0,
    comment: '',
  });
})();

const updateCompletedSteps = state => {
  const {
    formValues: {
      inn,
      // kpp,
      dirName,
      dirSurname,
      dirFatherName,
      address,
      regDate,
    },
    accountsList,
    cashesList,
  } = state;

  const inputSteps = [
    Boolean(
      inn &&
        // kpp &&
        dirName &&
        dirSurname &&
        dirFatherName &&
        address &&
        address.address &&
        regDate,
    ),
    (accountsList.length > 0 || cashesList.length > 0) &&
      accountsList.every(a =>
        Boolean(
          // a.company_acct_id ||
          a.name && a.acct_num && a.bank_id && a.bik && a.korr_acct_num,
        ),
      ) &&
      cashesList.every(c => Boolean(c.name)),
  ];
  return {
    ...state,
    completedSteps: [...inputSteps, inputSteps.every(Boolean)],
  };
};

const initialState = updateCompletedSteps({
  step: 0,
  ...allowedSteps(0),
  /**
   * @type {boolean[]}
   */
  completedSteps: [],

  formValues: {
    inn: '',
    kpp: '',
    dirName: '',
    dirSurname: '',
    dirFatherName: '',
    address: {
      address: '',
    },
    regDate: '',
  },
  orgData: null,
  orgDataIsFetching: false,
  orgDataDidInvalidate: false,
  orgDataFetchFailed: false,

  accountsList: [],
  /**
   * @type {Immutable.Map<LocalIndex, Object>}
   */
  accountsMap: new MapImmutable(),
  isBankDialogOpened: false,
  isBranchDialogOpened: false,
  dialogsToCreate: false,
  bank: null,
  cashesList: [],
  /**
   * @type {Immutable.Map<LocalIndex, Object>}
   */
  cashesMap: new MapImmutable(),
  editId: null,

  companyUuid: null,
  companyIsSending: false,
  companyIsSendFailed: false,
  companySendErrors: [],

  accountIsSending: false,
  /**
   * @type {Immutable.Map<LocalIndex, string[]>}
   */
  accountsSendErrors: new MapImmutable(),
  /**
   * @type {Immutable.Map<LocalIndex, string[]>}
   */
  cashesSendErrors: new MapImmutable(),
});

function fillForm(formValues, orgData) {
  if (!orgData) {
    return formValues;
  }

  const { kpp, type, name, management, state, address } = orgData.data;

  let fullFIO;

  if (type === 'INDIVIDUAL') {
    fullFIO = (name && name.full) || '';
  } else {
    if (management && management.name) {
      fullFIO = management.name;
    }
  }

  let dirName = '',
    dirSurname = '',
    dirFatherName = '';
  if (fullFIO) {
    const fio = fullFIO.split(/\s+/);
    switch (fio.length) {
      case 2:
        [dirSurname, dirName] = fio;
        break;

      case 3:
        [dirSurname, dirName, dirFatherName] = fio;
        break;

      default:
        dirName = fullFIO;
    }
  }

  return {
    ...formValues,
    kpp,
    dirName,
    dirSurname,
    dirFatherName,
    regDate:
      state && state.registration_date
        ? toIsoDate(new Date(state.registration_date))
        : '',
    address: address ? convertFromSuggestion(address) : { address: '' },
  };
}

function deleteAccount(accountsList, accountsMap, id) {
  return {
    accountsList: accountsList.filter(account => account._id !== id),
    accountsMap: accountsMap.delete(id),
  };
}

function deleteCash(cashesList, cashesMap, id) {
  return {
    cashesList: cashesList.filter(cash => cash._id !== id),
    cashesMap: cashesMap.delete(id),
  };
}

function editDone(state) {
  const { accountsList, accountsMap, cashesList, cashesMap, editId } = state;

  if (!editId) {
    return state;
  }

  const account = accountsMap.get(editId);
  if (account) {
    if (account._isNew) {
      return updateCompletedSteps({
        ...state,
        ...deleteAccount(accountsList, accountsMap, editId),
        editId: null,
      });
    }
  } else {
    const cash = cashesMap.get(editId);
    if (cash && cash._isNew) {
      return updateCompletedSteps({
        ...state,
        ...deleteCash(cashesList, cashesMap, editId),
        editId: null,
      });
    }
  }

  return {
    ...state,
    editId: null,
  };
}

export default (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case RESET:
      return initialState;

    case STEP_BACK: {
      if (state.step <= 0) {
        return state;
      }
      const step = state.step - 1;
      return {
        ...state,
        step,
        ...allowedSteps(step),
      };
    }

    case STEP_NEXT: {
      if (state.step >= _STEP_LAST) {
        return state;
      }
      const step = state.step + 1;
      return {
        ...state,
        step,
        ...allowedSteps(step),
      };
    }

    case STEP_TO: {
      const { step } = payload;
      if (step < 0 || step > _STEP_LAST) {
        return state;
      }
      return {
        ...state,
        step,
        ...allowedSteps(step),
      };
    }

    case FORM_CHANGE: {
      const { update } = payload;
      const formValues = {
        ...state.formValues,
        ...update,
      };
      return updateCompletedSteps({
        ...state,
        formValues,
        orgDataDidInvalidate:
          state.orgDataDidInvalidate || state.formValues.inn !== formValues.inn,
      });
    }

    case ORG_FETCH + _PENDING:
      return {
        ...state,
        orgDataIsFetching: true,
      };

    case ORG_FETCH + _FULFILLED: {
      return updateCompletedSteps({
        ...state,
        formValues: fillForm(state.formValues, payload),
        orgData: payload,
        orgDataIsFetching: false,
        orgDataDidInvalidate: false,
        orgDataFetchFailed: false,
      });
    }

    case ORG_FETCH + _REJECTED: {
      return {
        ...state,
        orgDataIsFetching: false,
        orgDataFetchFailed: true,
      };
    }

    case BANK_DIALOG_SHOW: {
      const { show, forCreate } = payload;
      return {
        ...state,
        isBankDialogOpened: show,
        dialogsToCreate: show ? forCreate : state.dialogsToCreate,
      };
    }

    case BRANCH_DIALOG_SHOW: {
      const { show, bank, forCreate } = payload;

      return {
        ...state,
        isBranchDialogOpened: show,
        dialogsToCreate: show ? forCreate : state.dialogsToCreate,
        bank: bank || null,
      };
    }

    case ACCOUNTS_CREATE: {
      const newState = editDone(state);
      const { bank, branch } = payload;
      const account = createAccount(bank, branch);
      const { accountsList, accountsMap } = newState;
      return updateCompletedSteps({
        ...newState,
        accountsList: [...accountsList, account],
        accountsMap: accountsMap.set(account._id, account),
        editId: account._id,
      });
    }

    case CASHES_CREATE: {
      const newState = editDone(state);
      const cash = createCash();
      const { cashesList, cashesMap } = newState;
      return updateCompletedSteps({
        ...newState,
        cashesList: [...cashesList, cash],
        cashesMap: cashesMap.set(cash._id, cash),
        editId: cash._id,
      });
    }

    case ACCOUNTS_DELETE: {
      const { id } = payload;
      const { accountsList, accountsMap, editId } = state;
      return updateCompletedSteps({
        ...state,
        ...deleteAccount(accountsList, accountsMap, id),
        editId: editId !== id ? editId : null,
      });
    }

    case CASHES_DELETE: {
      const { id } = payload;
      const { cashesList, cashesMap, editId } = state;
      return updateCompletedSteps({
        ...state,
        ...deleteCash(cashesList, cashesMap, id),
        editId: editId !== id ? editId : null,
      });
    }

    case ACCOUNTS_EDIT:
    case CASHES_EDIT: {
      const { id } = payload;
      return {
        ...editDone(state),
        editId: id,
      };
    }

    case ACCOUNTS_EDIT_DONE:
    case CASHES_EDIT_DONE:
      return editDone(state);

    case ACCOUNTS_FIELD_CHANGE: {
      const { editId, accountsMap, accountsList } = state;
      if (!editId) {
        return state;
      }
      let account = accountsMap.get(editId);
      if (!account) {
        return state;
      }

      const {
        update: { bank, branch, ...update },
        commit,
      } = payload;
      account = {
        ...account,
        ...update,
        ...(bank && branch ? accountFieldsFromBranch(bank, branch) : null),
        ...(commit ? { _isNew: false } : null),
      };

      return updateCompletedSteps({
        ...state,
        accountsList: accountsList.map(acc =>
          acc._id !== editId ? acc : account,
        ),
        accountsMap: accountsMap.set(editId, account),
      });
    }

    case CASHES_FIELD_CHANGE: {
      const { editId, cashesList, cashesMap } = state;
      if (!editId) {
        return state;
      }
      let cash = cashesMap.get(editId);
      if (!cash) {
        return state;
      }

      const { update, commit } = payload;
      cash = {
        ...cash,
        ...update,
        ...(commit ? { _isNew: false } : null),
      };

      return updateCompletedSteps({
        ...state,
        cashesList: cashesList.map(c => (c._id !== editId ? c : cash)),
        cashesMap: cashesMap.set(editId, cash),
      });
    }

    case COMPANY_SEND + _PENDING:
      return {
        ...state,
        companyIsSending: true,
      };

    case COMPANY_SEND + _FULFILLED: {
      return {
        ...state,
        companyUuid: payload.uuid,
        companyIsSending: false,
        companyIsSendFailed: false,
        companySendErrors: [],
      };
    }

    case COMPANY_SEND + _REJECTED: {
      return {
        ...state,
        companyIsSending: false,
        companyIsSendFailed: true,
        companySendErrors:
          payload && false === payload.status ? payload.message : [],
      };
    }

    case ACCOUNT_SEND + _PENDING:
    case CASH_SEND + _PENDING: {
      const { id } = meta;
      return {
        ...state,
        accountIsSending: id,
      };
    }

    case ACCOUNT_SEND + _FULFILLED: {
      const { ...update } = payload;
      const { id } = meta;
      const { accountsMap, accountsList, accountsSendErrors } = state;
      delete update.bankInfo;
      const account = {
        ...accountsMap.get(id),
        ...update,
      };
      return {
        ...state,
        accountIsSending: null,
        accountsSendErrors: accountsSendErrors.delete(id),
        accountsList: accountsList.map(acc => (acc._id !== id ? acc : account)),
        accountsMap: accountsMap.set(id, account),
      };
    }

    case CASH_SEND + _FULFILLED: {
      const { ...update } = payload;
      const { id } = meta;
      const { cashesMap, cashesList, cashesSendErrors } = state;
      // delete update.;
      const cash = {
        ...cashesMap.get(id),
        ...update,
      };
      return {
        ...state,
        accountIsSending: null,
        cashesSendErrors: cashesSendErrors.delete(id),
        cashesList: cashesList.map(c => (c._id !== id ? c : cash)),
        cashesMap: cashesMap.set(id, cash),
      };
    }

    case ACCOUNT_SEND + _REJECTED: {
      const { id } = meta;
      const { accountsSendErrors } = state;
      return {
        ...state,
        accountIsSending: null,
        accountsSendErrors: accountsSendErrors.set(
          id,
          payload && false === payload.status ? [payload.message] : [],
        ),
      };
    }

    case CASH_SEND + _REJECTED: {
      const { id } = meta;
      const { cashesSendErrors } = state;
      return {
        ...state,
        accountIsSending: null,
        cashesSendErrors: cashesSendErrors.set(
          id,
          payload && false === payload.status ? [payload.message] : [],
        ),
      };
    }

    default:
      return state;
  }
};
