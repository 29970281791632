import {
  COMPANIES_FETCH_FULFILLED,
  COMPANIES_FETCH_PENDING,
  COMPANIES_FETCH_REJECTED,
  COMPANIES_INVALIDATE,
} from 'actions/companies/types';
import { AUTH_LOGOUT } from 'actions/auth/types';

const initialState = {
  companiesList: [],
  companiesMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    companiesList: list,
    companiesMap: new Map(list.map(company => [company.uuid, company])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOGOUT:
      return initialState;

    case COMPANIES_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case COMPANIES_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case COMPANIES_FETCH_FULFILLED:
      return {
        ...state,
        ...makeStateFromList(payload),
        isAuthenticated: true,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };

    case COMPANIES_FETCH_REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    default:
      return state;
  }
};
