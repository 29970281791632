import {
  AUTH_REFRESH_FETCH_FULFILLED,
  AUTH_REFRESH_FETCH_PENDING,
  AUTH_REFRESH_FETCH_REJECTED,
  AUTH_REFRESH_PLANNED,
  AUTH_REFRESH_PLANNED_ABORT,
  AUTH_REFRESH_SET_TOKEN,
} from 'actions/auth-refresh/types';
import { AUTH_LOGOUT } from 'actions/auth/types';
import { makeInitialLoader } from 'storage/persistent';

const emptyState = {
  refreshToken: null,
  refreshTokenExpires: null,
  isFetching: false,
  isFetchFailed: false,
  plannedAbort: null,
};
const makeInitialState = makeInitialLoader(
  emptyState,
  ({ authRefresh }) => authRefresh,
);

function abortOldPlanned(state) {
  const { plannedAbort } = state;
  if (plannedAbort) {
    plannedAbort();
  }
}

export default (state = makeInitialState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_REFRESH_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case AUTH_REFRESH_SET_TOKEN: {
      const { refresh_token, refresh_token_exp } = payload;

      return {
        ...state,
        refreshToken: refresh_token,
        refreshTokenExpires: refresh_token_exp,
      };
    }

    case AUTH_REFRESH_FETCH_FULFILLED: {
      const { refresh_token, refresh_token_exp } = payload;

      return {
        ...state,
        refreshToken: refresh_token,
        refreshTokenExpires: refresh_token_exp,
        isFetching: false,
        isFetchFailed: false,
      };
    }

    case AUTH_REFRESH_FETCH_REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    case AUTH_LOGOUT: {
      abortOldPlanned(state);
      return emptyState;
    }

    case AUTH_REFRESH_PLANNED: {
      const { abort } = payload;
      abortOldPlanned(state);
      return {
        ...state,
        plannedAbort: abort,
      };
    }

    case AUTH_REFRESH_PLANNED_ABORT: {
      abortOldPlanned(state);
      return {
        ...state,
        plannedAbort: null,
      };
    }

    default:
      return state;
  }
};
