import {
  AUTH_SIGNUP_CHANGE,
  AUTH_SIGNUP_FETCH_FULFILLED,
  AUTH_SIGNUP_FETCH_PENDING,
  AUTH_SIGNUP_FETCH_REJECTED,
} from 'actions/auth-signup/types';

const initialState = {
  values: {
    email: '',
    phone: '',
    first_name: '',
    password: '',
    check_conditions: false,
  },
  errorMessage: null,
  isFetching: false,
  isFetchFailed: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case AUTH_SIGNUP_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload,
        },
      };

    case AUTH_SIGNUP_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case AUTH_SIGNUP_FETCH_FULFILLED:
      return initialState;

    case AUTH_SIGNUP_FETCH_REJECTED:
      return {
        ...state,
        errorMessage:
          error && false === payload.status && payload.message
            ? // TODO: fix API inconsistency
              Array.isArray(payload.message)
              ? payload.message
              : [payload.message]
            : null,
        isFetching: false,
        isFetchFailed: true,
      };

    default:
      return state;
  }
};
