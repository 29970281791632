const NS = 'COMPANY-ADD/';

export const RESET = NS + 'RESET';
export const STEP_BACK = NS + 'STEP_BACK';
export const STEP_NEXT = NS + 'STEP_NEXT';
export const STEP_TO = NS + 'STEP_TO';
export const FORM_CHANGE = NS + 'FORM_CHANGE';

export const ORG_FETCH = NS + 'ORG_FETCH';

export const BANK_DIALOG_SHOW = NS + 'BANK_DIALOG_SHOW';
export const BRANCH_DIALOG_SHOW = NS + 'BRANCH_DIALOG_SHOW';
export const ACCOUNTS_CREATE = NS + 'ACCOUNTS_CREATE';
export const ACCOUNTS_DELETE = NS + 'ACCOUNTS_DELETE';
export const ACCOUNTS_EDIT = NS + 'ACCOUNTS_EDIT';
export const ACCOUNTS_EDIT_DONE = NS + 'ACCOUNTS_EDIT_DONE';
export const ACCOUNTS_FIELD_CHANGE = NS + 'ACCOUNTS_FIELD_CHANGE';
export const CASHES_CREATE = NS + 'CASHES_CREATE';
export const CASHES_DELETE = NS + 'CASHES_DELETE';
export const CASHES_EDIT = NS + 'CASHES_EDIT';
export const CASHES_EDIT_DONE = NS + 'CASHES_EDIT_DONE';
export const CASHES_FIELD_CHANGE = NS + 'CASHES_FIELD_CHANGE';

export const COMPANY_SEND = NS + 'COMPANY_SEND';
export const ACCOUNT_SEND = NS + 'ACCOUNT_SEND';
export const CASH_SEND = NS + 'CASH_SEND';

export const _PENDING = '_PENDING';
export const _FULFILLED = '_FULFILLED';
export const _REJECTED = '_REJECTED';
