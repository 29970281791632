import {
  _FULFILLED,
  _PENDING,
  _REJECTED,
  ADD,
  DELETE,
  FETCH,
  INVALIDATE,
  UPDATE,
} from 'actions/settings/okveds/types';

const initialState = {
  okvedList: [],
  okvedMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
  lastFailMessage: null,
};

function makeStateFromList(list) {
  return {
    okvedList: list,
    okvedMap: new Map(list.map(okved => [okved.id, okved])),
  };
}

export default (state = initialState, action) => {
  const { type, payload, meta, error } = action;
  switch (type) {
    case INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case ADD + _PENDING:
    case DELETE + _PENDING:
    case UPDATE + _PENDING:
    case FETCH + _PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH + _FULFILLED:
      return {
        ...state,
        ...makeStateFromList(payload),
        didInvalidate: false,
        didFetched: true,
        isFetching: false,
        isFetchFailed: false,
        lastFailMessage: null,
      };

    case ADD + _FULFILLED:
    case UPDATE + _FULFILLED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: false,
        lastFailMessage: null,
      };

    case DELETE + _FULFILLED: {
      const { id } = meta;
      return {
        ...state,
        ...makeStateFromList(state.okvedList.filter(item => item.id !== id)),
        isFetching: false,
        isFetchFailed: false,
        lastFailMessage: null,
      };
    }

    case ADD + _REJECTED:
    case DELETE + _REJECTED:
    case UPDATE + _REJECTED:
    case FETCH + _REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
        lastFailMessage:
          (error && payload && false === payload.status && payload.message) ||
          null,
      };

    default:
      return state;
  }
};
