import React from 'react';
import { Alert, Button, Spin } from 'antd';
import { LoadingComponentProps } from 'react-loadable';

export default function LoadingStub(props: LoadingComponentProps) {
  const { error, timedOut, pastDelay, retry } = props;

  if (error || timedOut) {
    return (
      <Alert
        type="error"
        message={'Ошибка'}
        description={
          <div>
            {error
              ? 'Не удалось загрузить ресурс.'
              : 'Загрузка ресурса заняла слишком много времени.'}
            <br />
            <Button onClick={retry}>Повторить</Button>
          </div>
        }
        showIcon
      />
    );
  }
  if (pastDelay) {
    return (
      <>
        <Spin /> Загрузка...
      </>
    );
  }
  return null;
}
