import {
  CHANGE_COLOR_OPTION,
  CHANGE_BACKGROUND,
  CHANGE_WIDGETS,
  CustomizerAction,
} from 'actions/customizer/types';

import { makeInitialLoader } from 'storage/persistent';

export interface State {
  colorOption: string;
  background: string;
  widgetsContentTop: string[] | null;
}

const initialState: State = {
  colorOption: '1',
  background: '1',
  widgetsContentTop: null,
};

const makeInitialState: () => State = makeInitialLoader<State>(
  initialState,
  ({ customizer }) => customizer,
);

export default (
  state = makeInitialState(),
  action: CustomizerAction,
): State => {
  switch (action.type) {
    case CHANGE_COLOR_OPTION:
      return {
        ...state,
        colorOption: action.payload,
      };
    case CHANGE_BACKGROUND:
      return {
        ...state,
        background: action.payload,
      };

    case CHANGE_WIDGETS: {
      const {
        widgets,
        // place,
      } = action.payload;
      return {
        ...state,
        widgetsContentTop: widgets,
      };
    }

    default:
      return state;
  }
};
