import * as types from 'actions/partners/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  partnersList: [],
  partnersMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    partnersList: list,
    partnersMap: new Map(list.map(partner => [partner.partner_id, partner])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case types.PARTNERS_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case types.PARTNERS_FETCH + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.PARTNERS_FETCH + types.FULFILLED: {
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.PARTNERS_FETCH + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.PARTNER_ADD + types.PENDING:
    case types.PARTNER_EDIT + types.PENDING:
    case types.PARTNER_DELETE + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.PARTNER_EDIT + types.FULFILLED: {
      const newPartner = payload;
      const partnersList = state.partnersList.map(partner => {
        return partner.partner_id === newPartner.partner_id
          ? newPartner
          : partner;
      });
      const partnersMap = new Map(state.partnersMap);
      partnersMap.set(newPartner.partner_id, newPartner);
      return {
        ...state,
        partnersList,
        partnersMap,
        isFetching: false,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.PARTNER_ADD + types.REJECTED:
    case types.PARTNER_EDIT + types.REJECTED:
    case types.PARTNER_DELETE + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.PARTNER_ADD + types.FULFILLED: {
      const newPartner = payload;
      const partnersList = state.partnersList.slice();
      partnersList.push(newPartner);
      const partnersMap = new Map(state.partnersMap);
      partnersMap.set(newPartner.partner_id, newPartner);
      return {
        ...state,
        partnersList,
        partnersMap,
        isFetching: false,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};
