import * as types from 'actions/handbooks/types';

const handbookInitialState = () => ({
  data: [],
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
});

const keyedSections = {
  branchesOf: {
    __fallback: handbookInitialState(),
  },
  okvedItems: {
    __fallback: handbookInitialState(),
  },
};
const initialState = {
  banks: handbookInitialState(),
  okved: handbookInitialState(),
  partnerCategorySections: handbookInitialState(),
  propertyTypes: handbookInitialState(),
  ...keyedSections,
};

function updateStateSingle(state, name, update) {
  return {
    ...state,
    [name]: {
      ...state[name],
      ...update,
    },
  };
}

function updateStateKeyed(state, name, key, update) {
  return {
    ...state,
    [name]: {
      ...state[name],
      [key]: {
        ...(state[name][key] || handbookInitialState()),
        ...update,
      },
    },
  };
}

function updateState(state, name, key, update) {
  return keyedSections[name]
    ? updateStateKeyed(state, name, key, update)
    : updateStateSingle(state, name, update);
}

export default (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case types.HANDBOOK_FETCH + types.PENDING: {
      const { name, key } = meta;
      return updateState(state, name, key, {
        isFetching: true,
      });
    }
    case types.HANDBOOK_FETCH + types.REJECTED: {
      const { name, key } = meta;
      return updateState(state, name, key, {
        isFetching: false,
        isFetchFailed: true,
      });
    }
    case types.HANDBOOK_FETCH + types.FULFILLED: {
      const { name, key } = meta;
      return updateState(state, name, key, {
        data: payload,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
      });
    }
    default:
      return state;
  }
};
