import {
  AUTH_LOGIN_CHANGE,
  AUTH_LOGIN_FETCH_FULFILLED,
  AUTH_LOGIN_FETCH_PENDING,
  AUTH_LOGIN_FETCH_REJECTED,
} from 'actions/auth-login/types';

const initialState = {
  values: {
    email: '',
    password: '',
  },
  errorMessage: null,
  isFetching: false,
  isFetchFailed: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case AUTH_LOGIN_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload,
        },
      };

    case AUTH_LOGIN_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case AUTH_LOGIN_FETCH_FULFILLED:
      return initialState;

    case AUTH_LOGIN_FETCH_REJECTED:
      return {
        ...state,
        errorMessage:
          error && false === payload.status && payload.message
            ? payload.message
            : null,
        isFetching: false,
        isFetchFailed: true,
      };

    default:
      return state;
  }
};
