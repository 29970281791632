import { Map } from 'immutable';
import {
  FETCH_FULFILLED,
  FETCH_PENDING,
  FETCH_REJECTED,
  FREE,
  INVALIDATE,
  PartnerGroupsAction,
  SEND_FULFILLED,
  SEND_PENDING,
  SEND_REJECTED,
} from 'actions/partner-groups/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';
import { Group, GroupId } from '../types/api/partner-groups/group';
import { SectionId } from '../types/api/partner-groups/section';

export interface State {
  allGroupsMap: Map<GroupId, Group>;
  sectionGroupsList: Map<SectionId, Group[]>;
  isFetching: boolean;
  didFetched: boolean;
  isFetchFailed: boolean;
  didInvalidate: boolean;
  sendingId: GroupId | null;
  isSending: boolean;
  isSendFailed: boolean;
}

const initialState: State = {
  allGroupsMap: Map(),
  sectionGroupsList: Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
  sendingId: null,
  isSending: false,
  isSendFailed: false,
};

const makeState = (
  payload: Group[],
): Pick<State, 'allGroupsMap' | 'sectionGroupsList'> => {
  let allGroupsMap: State['allGroupsMap'] = Map();
  let sectionGroupsList: State['sectionGroupsList'] = Map();
  payload.forEach(group => {
    allGroupsMap = allGroupsMap.set(group.id, group);
    sectionGroupsList = sectionGroupsList.update(
      group.categoryInfo.id,
      [],
      list => [...list, group],
    );
  });
  return {
    allGroupsMap,
    sectionGroupsList,
  };
};

const updateState = (
  state: State,
  payload: Group[],
): Pick<State, 'allGroupsMap' | 'sectionGroupsList'> => {
  let { allGroupsMap, sectionGroupsList } = state;
  payload.forEach(group => {
    if (!allGroupsMap.has(group.id)) {
      allGroupsMap = allGroupsMap.set(group.id, group);
      sectionGroupsList = sectionGroupsList.update(
        group.categoryInfo.id,
        [],
        list => [...list, group],
      );
    }
  });
  return {
    allGroupsMap,
    sectionGroupsList,
  };
};

export default (state = initialState, action: PartnerGroupsAction): State => {
  switch (action.type) {
    case COMPANY_UNSELECT_UUID:
    case FREE:
      return initialState;

    case INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_FULFILLED:
      return {
        ...state,
        ...makeState(action.payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };

    case FETCH_REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    case SEND_PENDING: {
      const { groupId } = action.meta;
      return {
        ...state,
        sendingId: groupId,
        isSending: true,
      };
    }

    case SEND_FULFILLED: {
      const group = action.payload;
      const groups = [group];
      return {
        ...state,
        ...updateState(state, groups),
        sendingId: null,
        isSending: false,
        isSendFailed: false,
      };
    }

    case SEND_REJECTED:
      return {
        ...state,
        sendingId: null,
        isSending: false,
        isSendFailed: true,
      };

    default:
      return state;
  }
};
