export const PENDING = '_PENDING';
export const FULFILLED = '_FULFILLED';
export const REJECTED = '_REJECTED';

export const COMPANY_SELECT_UUID = 'COMPANY_SELECT_UUID';
export const COMPANY_UNSELECT_UUID = 'COMPANY_UNSELECT_UUID';
export const COMPANY_INVALIDATE = 'COMPANY_INVALIDATE';
export const COMPANY_FETCH = 'COMPANY_FETCH';
export const COMPANY_FIELD_SAVED = 'COMPANY_FIELD_SAVED';
export const COMPANY_FIELD_EDIT = 'COMPANY_FIELD_EDIT';
export const COMPANY_UPDATE_STYLE = 'COMPANY_UPDATE_STYLE';
