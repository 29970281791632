import {
  AsyncAction,
  AsyncFulfilledAction,
} from 'types/fix/redux-thunk-promise';
import { Group, GroupId } from 'types/api/partner-groups/group';

export const INVALIDATE = 'COMPANY/PARTNER_GROUPS/INVALIDATE';
export const FREE = 'COMPANY/PARTNER_GROUPS/FREE';
export const FETCH = 'COMPANY/PARTNER_GROUPS/FETCH';
export const FETCH_PENDING = 'COMPANY/PARTNER_GROUPS/FETCH_PENDING';
export const FETCH_FULFILLED = 'COMPANY/PARTNER_GROUPS/FETCH_FULFILLED';
export const FETCH_REJECTED = 'COMPANY/PARTNER_GROUPS/FETCH_REJECTED';
export const SEND = 'COMPANY/PARTNER_GROUPS/SEND';
export const SEND_PENDING = 'COMPANY/PARTNER_GROUPS/SEND_PENDING';
export const SEND_FULFILLED = 'COMPANY/PARTNER_GROUPS/SEND_FULFILLED';
export const SEND_REJECTED = 'COMPANY/PARTNER_GROUPS/SEND_REJECTED';

export interface InvalidateAction {
  type: typeof INVALIDATE;
}

export interface FreeAction {
  type: typeof FREE;
}

export interface FetchAction extends AsyncAction<Group[]> {
  type: typeof FETCH;
}

export interface FetchPendingAction {
  type: typeof FETCH_PENDING;
}

export type FetchFulfilledAction = AsyncFulfilledAction<
  FetchAction,
  typeof FETCH_FULFILLED
>;

export interface FetchRejectedAction {
  type: typeof FETCH_REJECTED;
}

interface SendMeta {
  groupId: GroupId | null;
}

export interface SendAction extends AsyncAction<Group> {
  type: typeof SEND;
  meta: SendMeta;
}

export interface SendPendingAction {
  type: typeof SEND_PENDING;
  meta: SendMeta;
}

export type SendFulfilledAction = AsyncFulfilledAction<
  SendAction,
  typeof SEND_FULFILLED
>;

export interface SendRejectedAction {
  type: typeof SEND_REJECTED;
  meta: SendMeta;
}

export type PartnerGroupsAction =
  | InvalidateAction
  | FreeAction
  | FetchAction
  | FetchPendingAction
  | FetchFulfilledAction
  | FetchRejectedAction
  | SendAction
  | SendPendingAction
  | SendFulfilledAction
  | SendRejectedAction;
