import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import auth from './auth';
import authLogin from './auth-login';
import authRefresh from './auth-refresh';
import authSignup from './auth-signup';
import companies from './companies';
import company from './company';
import companyAccounts from './company-accounts';
import companyAdd from './company-add';
import companyCashes from './company-cashes';
import companyEvents from './company-events';
import customizer from './customizer';
import docs from './docs';
import files from './files';
import handbooks from './handbooks';
import import_ from './import';
import partners from './partners';
import partnerGroups from './partner-groups';
import settings from './settings';
import users from './users';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),

    auth,
    authLogin,
    authRefresh,
    authSignup,
    companies,
    company,
    companyAccounts,
    companyAdd,
    companyCashes,
    companyEvents,
    customizer,
    docs,
    files,
    handbooks,
    import: import_,
    partners,
    partnerGroups,
    settings,
    users,
  });

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
