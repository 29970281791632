import {
  FULFILLED,
  PENDING,
  FETCH,
  INVALIDATE,
  FILE_UPLOAD,
  REJECTED,
} from 'actions/files/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  filesList: [],
  filesMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    filesList: list,
    filesMap: new Map(list.map(file => [file.id, file])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case FETCH + PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH + FULFILLED:
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        didInvalidate: false,
        isFetchFailed: false,
      };

    case FETCH + REJECTED:
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };

    case FILE_UPLOAD + FULFILLED:
      return {
        ...state,
      };
    case FILE_UPLOAD + PENDING:
      return {
        ...state,
      };
    case FILE_UPLOAD + REJECTED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
