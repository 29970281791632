export const PENDING = '_PENDING';
export const FULFILLED = '_FULFILLED';
export const REJECTED = '_REJECTED';

export const DOCS_INVALIDATE = 'DOCS_INVALIDATE';
export const DOCS_FETCH = 'DOCS_FETCH';
export const DOC_GET = 'DOC_GET';
export const DOC_EDIT = 'DOC_EDIT';
export const DOC_ADD = 'DOC_ADD';
export const DOC_DELETE = 'DOC_DELETE';
