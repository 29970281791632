import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LocaleProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import 'moment/locale/ru';
import configureStore, { history } from './store';

const store = configureStore();

export default function renderApp(
  Component: React.ComponentType,
  rootEl = document.getElementById('root'),
) {
  return render(
    <Provider store={store}>
      <LocaleProvider locale={ruRU}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </LocaleProvider>
    </Provider>,
    rootEl,
  );
}
