import { AsyncAction } from 'redux-promise-middleware';
import { CompanyEvent } from 'types/api/company/events';

export const INVALIDATE = 'COMPANY/EVENTS/INVALIDATE';
export const FREE = 'COMPANY/EVENTS/FREE';
export const TOGGLE_FINISHED = 'COMPANY/EVENTS/TOGGLE_FINISHED';
export const FETCH = 'COMPANY/EVENTS/FETCH';
export const FETCH_PENDING = 'COMPANY/EVENTS/FETCH_PENDING';
export const FETCH_FULFILLED = 'COMPANY/EVENTS/FETCH_FULFILLED';
export const FETCH_REJECTED = 'COMPANY/EVENTS/FETCH_REJECTED';

export interface InvalidateAction {
  type: typeof INVALIDATE;
}

export interface FreeAction {
  type: typeof FREE;
}

export interface ToggleFinishedAction {
  type: typeof TOGGLE_FINISHED;
  payload: {
    show: boolean;
  };
}

export interface FetchAction extends AsyncAction {
  type: typeof FETCH;
  payload: Promise<CompanyEvent[]>;
}

export interface FetchPendingAction {
  type: typeof FETCH_PENDING;
}
export interface FetchFulfilledAction {
  type: typeof FETCH_FULFILLED;
  payload: CompanyEvent[];
}
export interface FetchRejectedAction {
  type: typeof FETCH_REJECTED;
}

export type CompanyEventsActionType =
  | InvalidateAction
  | FreeAction
  | FetchAction
  | FetchPendingAction
  | FetchFulfilledAction
  | FetchRejectedAction
  | ToggleFinishedAction;
