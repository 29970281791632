import {
  AsyncAction,
  AsyncFulfilledAction,
} from 'types/fix/redux-thunk-promise';
import {
  ImportRow,
  ImportRowId,
  ImportRowSend,
  ItemsFilterParams,
} from 'types/api/import/statement/row';
import { StatementUpload } from 'types/api/import/statement/upload';
import { ApiStatus } from 'types/api/_common/status';

export const FILTER_CHANGE = 'IMPORT/STATEMENTS/ITEMS/FILTER_CHANGE';
export const FILTER_RESET = 'IMPORT/STATEMENTS/ITEMS/FILTER_RESET';
export const INVALIDATE = 'IMPORT/STATEMENTS/ITEMS/INVALIDATE';
export const FREE = 'IMPORT/STATEMENTS/ITEMS/FREE';
export const FETCH = 'IMPORT/STATEMENTS/ITEMS/FETCH';
export const FETCH_PENDING = 'IMPORT/STATEMENTS/ITEMS/FETCH_PENDING';
export const FETCH_FULFILLED = 'IMPORT/STATEMENTS/ITEMS/FETCH_FULFILLED';
export const FETCH_REJECTED = 'IMPORT/STATEMENTS/ITEMS/FETCH_REJECTED';
export const UPLOAD = 'IMPORT/STATEMENTS/ITEMS/UPLOAD';
export const UPLOAD_PENDING = 'IMPORT/STATEMENTS/ITEMS/UPLOAD_PENDING';
export const UPLOAD_FULFILLED = 'IMPORT/STATEMENTS/ITEMS/UPLOAD_FULFILLED';
export const UPLOAD_REJECTED = 'IMPORT/STATEMENTS/ITEMS/UPLOAD_REJECTED';
export const CHECKED = 'IMPORT/STATEMENTS/ITEMS/CHECKED';
export const APPROVE = 'IMPORT/STATEMENTS/ITEMS/APPROVE';
export const APPROVE_PENDING = 'IMPORT/STATEMENTS/ITEMS/APPROVE_PENDING';
export const APPROVE_FULFILLED = 'IMPORT/STATEMENTS/ITEMS/APPROVE_FULFILLED';
export const APPROVE_REJECTED = 'IMPORT/STATEMENTS/ITEMS/APPROVE_REJECTED';

export interface InvalidateAction {
  type: typeof INVALIDATE;
}

export interface FilterResetAction {
  type: typeof FILTER_RESET;
}

export interface FilterChangeAction {
  type: typeof FILTER_CHANGE;
  payload: Partial<ItemsFilterParams>;
}

export interface FreeAction {
  type: typeof FREE;
}

export interface FetchAction extends AsyncAction<ImportRow[]> {
  type: typeof FETCH;
}

export interface FetchPendingAction {
  type: typeof FETCH_PENDING;
}
export type FetchFulfilledAction = AsyncFulfilledAction<
  FetchAction,
  typeof FETCH_FULFILLED
>;
export interface FetchRejectedAction {
  type: typeof FETCH_REJECTED;
}

export interface UploadAction extends AsyncAction<StatementUpload> {
  type: typeof UPLOAD;
}

export interface UploadPendingAction {
  type: typeof UPLOAD_PENDING;
}
export type UploadFulfilledAction = AsyncFulfilledAction<
  UploadAction,
  typeof UPLOAD_FULFILLED
>;
export interface UploadRejectedAction {
  type: typeof UPLOAD_REJECTED;
}

type ApproveMeta = {
  id: ImportRowId;
  update: ImportRowSend;
};

export interface ApproveAction extends AsyncAction<ApiStatus> {
  type: typeof APPROVE;
  meta: ApproveMeta;
}

export interface ApprovePendingAction {
  type: typeof APPROVE_PENDING;
  meta: ApproveMeta;
}
export type ApproveFulfilledAction = AsyncFulfilledAction<
  ApproveAction,
  typeof APPROVE_FULFILLED
>;
export interface ApproveRejectedAction {
  type: typeof APPROVE_REJECTED;
  meta: ApproveMeta;
}

export interface CheckedAction {
  type: typeof CHECKED;
  payload: {
    id: number;
    checked: boolean;
  };
}

export type ItemsActionType =
  | InvalidateAction
  | FilterChangeAction
  | FilterResetAction
  | FreeAction
  | FetchAction
  | FetchPendingAction
  | FetchFulfilledAction
  | FetchRejectedAction
  | UploadAction
  | UploadPendingAction
  | UploadFulfilledAction
  | UploadRejectedAction
  | ApproveAction
  | ApprovePendingAction
  | ApproveFulfilledAction
  | ApproveRejectedAction
  | CheckedAction;
