import { dataOnly } from '../instance';
import { ApiStatus } from 'types/api/_common/status';
import { StatementTotals } from 'types/api/import/statement/totals';
import { StatementUpload } from 'types/api/import/statement/upload';
import {
  ImportRow,
  ImportRowId,
  ImportRowSend,
  ItemsFilterParams,
} from 'types/api/import/statement/row';

const e = encodeURIComponent;

type ItemsParams = Partial<ItemsFilterParams> & {
  start?: number;
  limit?: number;
};

export default {
  preupload: (uuid: string, id: number) =>
    dataOnly.get<any, StatementTotals>(
      `/import/statement/preupload/${e(uuid)}/${id}`,
    ),

  upload: (uuid: string, id: number) =>
    dataOnly.post<any, StatementUpload>(
      `/import/statement/upload/${e(uuid)}/${id}`,
    ),

  items: (uuid: string, params?: ItemsParams) =>
    dataOnly.get<any, ImportRow[]>(`/import/statement/items/${e(uuid)}`, {
      params,
    }),

  update: (uuid: string, id: ImportRowId, data: ImportRowSend) =>
    dataOnly.put<any, ApiStatus>(
      `/import/statement/items/${e(uuid)}/${id}`,
      data,
    ),
};

export const compareItems = (a: ImportRow, b: ImportRow): number =>
  b.data < a.data ? -1 : b.data > a.data ? 1 : a.id - b.id;
