import { combineReducers } from 'redux';

import accounts from './accounts';
import okveds from './okveds';
import requisite from './requisite';
import styles from './styles';

export default combineReducers({
  accounts,
  okveds,
  requisite,
  styles,
});
