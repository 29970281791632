import * as types from 'actions/settings/styles/types';

const initialState = {
  pending: {},
  rejected: {},
};

export default (state = initialState, action) => {
  const { type, /*payload,*/ meta } = action;

  switch (type) {
    case types.FILE_DELETE + types.FULFILLED:
    case types.FILE_UPLOAD + types.FULFILLED: {
      const { styleName } = meta;
      return {
        ...state,
        pending: { ...state.pending, [styleName]: false },
        rejected: { ...state.rejected, [styleName]: false },
      };
    }
    case types.FILE_DELETE + types.PENDING:
    case types.FILE_UPLOAD + types.PENDING: {
      const { styleName } = meta;
      return {
        ...state,
        pending: { ...state.pending, [styleName]: true },
        rejected: { ...state.rejected, [styleName]: false },
      };
    }
    case types.FILE_DELETE + types.REJECTED:
    case types.FILE_UPLOAD + types.REJECTED: {
      const { styleName } = meta;
      return {
        ...state,
        pending: { ...state.pending, [styleName]: false },
        rejected: { ...state.rejected, [styleName]: true },
      };
    }
    default:
      return state;
  }
};
