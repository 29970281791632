/**
 * Returns a new object with properties that met the conditions
 * @param object Source object to filter
 * @param filter filter Callback to call for every own property
 * @return New object without properties that did not met the conditions
 */
export default function filter<T, K extends keyof T>(
  object: T,
  filter: (key: K, value: T[K], object: T) => boolean,
): { [P in keyof T]?: T[P] } {
  const result = {} as T;
  (Object.keys(object) as K[]).forEach(key => {
    const value = object[key];
    if (filter(key, value, object)) {
      result[key] = value;
    }
  });
  return result;
}
