import React from 'react';
import { Route, Switch } from 'react-router';

import loadable from 'components/utils/loadable';
import './App.scss';

const Auth = loadable(import('./routes/auth'));
const MainApp = loadable(import('./components/MainApp'));

const App = () => (
  <Switch>
    <Route path="/auth" component={Auth} />
    <Route path="/" component={MainApp} />
  </Switch>
);

export default App;
