import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

const middlewares: Array<Middleware<any, any, any>> = [];

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_DEBUG_STORE
) {
  const logger: Middleware = (store: MiddlewareAPI) => (
    next: Dispatch<AnyAction>,
  ) => (action: AnyAction) => {
    console.log('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState());
    return result;
  };

  const crashReporter: Middleware = (/*store: MiddlewareAPI*/) => (
    next: Dispatch<AnyAction>,
  ) => (action: AnyAction) => {
    try {
      return next(action);
    } catch (err) {
      console.error('Caught an exception!', err);
      throw err;
    }
  };

  middlewares.push(logger, crashReporter);
}

export default middlewares;
