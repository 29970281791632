/**
 *
 * @param date
 * @return string
 */
export default function toIsoDate(date: Date): string {
  return [
    date.getUTCFullYear(),
    `0${date.getUTCMonth() + 1}`.substr(-2),
    `0${date.getUTCDate()}`.substr(-2),
  ].join('-');
}
