import {
  COMPANY_ACCOUNTS_INVALIDATE,
  COMPANY_ACCOUNTS_FETCH,
  COMPANY_ACCOUNT_EDIT,
  COMPANY_ACCOUNT_ADD,
  REJECTED,
  FULFILLED,
  PENDING,
  COMPANY_ACCOUNT_DELETE,
} from 'actions/company-accounts/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  accountsList: [],
  accountsMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    accountsList: list,
    accountsMap: new Map(
      list.map(account => [account.company_acct_id, account]),
    ),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case COMPANY_ACCOUNTS_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case COMPANY_ACCOUNTS_FETCH + PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case COMPANY_ACCOUNTS_FETCH + FULFILLED: {
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case COMPANY_ACCOUNTS_FETCH + REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case COMPANY_ACCOUNT_ADD + PENDING:
    case COMPANY_ACCOUNT_EDIT + PENDING:
    case COMPANY_ACCOUNT_DELETE + PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case COMPANY_ACCOUNT_EDIT + FULFILLED: {
      const { account: newAccount } = payload;
      const accountsList = state.accountsList.map(item => {
        return item.company_acct_id === newAccount.company_acct_id
          ? newAccount
          : item;
      });
      const accountsMap = new Map(state.accountsMap);
      accountsMap.set(newAccount.company_acct_id, newAccount);
      return {
        ...state,
        accountsList,
        accountsMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case COMPANY_ACCOUNT_ADD + REJECTED:
    case COMPANY_ACCOUNT_EDIT + REJECTED:
    case COMPANY_ACCOUNT_DELETE + REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case COMPANY_ACCOUNT_ADD + FULFILLED: {
      const { account } = payload;
      const accountsList = state.accountsList.slice();
      accountsList.push(account);
      const accountsMap = new Map(state.accountsMap);
      accountsMap.set(account.company_acct_id, account);
      return {
        ...state,
        accountsList,
        accountsMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case COMPANY_ACCOUNT_DELETE + FULFILLED: {
      const { accountId } = payload;
      const accountsList = state.accountsList.filter(
        item => item.company_acct_id !== accountId,
      );
      const accountsMap = new Map(state.accountsMap);
      accountsMap.delete(accountId);
      return {
        ...state,
        accountsList,
        accountsMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};
