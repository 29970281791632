const NS = 'SETTINGS/REQUISITE/';

export const FIELD_CHANGE = NS + 'FIELD_CHANGE';
export const CANCEL_CHANGE = NS + 'CANCEL_CHANGE';
export const SEND = NS + 'SEND';
export const SEND_PENDING = NS + 'SEND_PENDING';
export const SEND_FULFILLED = NS + 'SEND_FULFILLED';
export const SEND_REJECTED = NS + 'SEND_REJECTED';
export const SUCCESS_SHOW = NS + 'SUCCESS_SHOW';
export const SUCCESS_TIMED_OUT = NS + 'SUCCESS_TIMED_OUT';
