import {
  INVALIDATE,
  FREE,
  FETCH_REJECTED,
  FETCH_FULFILLED,
  FETCH_PENDING,
  TOGGLE_FINISHED,
  CompanyEventsActionType,
} from 'actions/company-events/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';
import { CompanyEvent } from 'types/api/company/events';
import { compareItems } from 'api/company/events';
import illFactory, {
  State as illState,
} from 'components/common/infinite-load-list/state-tools';

export interface State extends illState<CompanyEvent, 'id'> {
  showFinished: boolean;
  isFetching: boolean;
  didFetched: boolean;
  isFetchFailed: boolean;
  didInvalidate: boolean;
}

const illTools = illFactory(compareItems, 'id');

const initialState: State = {
  ...illTools.initialState,
  showFinished: false,
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

export default (
  state = initialState,
  action: CompanyEventsActionType,
): State => {
  switch (action.type) {
    case COMPANY_UNSELECT_UUID:
    case FREE:
      return initialState;

    case INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
        nextWanted: 0,
      };

    case TOGGLE_FINISHED:
      return {
        ...state,
        showFinished: action.payload.show,
      };

    case FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_FULFILLED: {
      if (!state.isFetching) {
        return state;
      }

      return {
        ...state,
        ...illTools.addStateItems(state, action.payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case FETCH_REJECTED: {
      if (!state.isFetching) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    default:
      return state;
  }
};
