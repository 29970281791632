import * as types from 'actions/company-cashes/types';
import { COMPANY_UNSELECT_UUID } from 'actions/company/types';

const initialState = {
  cashesList: [],
  cashesMap: new Map(),
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

function makeStateFromList(list) {
  return {
    cashesList: list,
    cashesMap: new Map(list.map(cash => [cash.id, cash])),
  };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_UNSELECT_UUID:
      return initialState;

    case types.COMPANY_CASHES_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case types.COMPANY_CASHES_FETCH + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.COMPANY_CASHES_FETCH + types.FULFILLED: {
      return {
        ...state,
        ...makeStateFromList(payload),
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.COMPANY_CASHES_FETCH + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.COMPANY_CASHE_ADD + types.PENDING:
    case types.COMPANY_CASHE_EDIT + types.PENDING:
    case types.COMPANY_CASHE_DELETE + types.PENDING: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.COMPANY_CASHE_EDIT + types.FULFILLED: {
      const { cash: newCash } = payload;
      const cashesList = state.cashesList.map(cash => {
        return cash.id === newCash.id ? newCash : cash;
      });
      const cashesMap = new Map(state.cashesMap);
      cashesMap.set(newCash.id, newCash);
      return {
        ...state,
        cashesList,
        cashesMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.COMPANY_CASHE_ADD + types.REJECTED:
    case types.COMPANY_CASHE_EDIT + types.REJECTED:
    case types.COMPANY_CASHE_DELETE + types.REJECTED: {
      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.COMPANY_CASHE_ADD + types.FULFILLED: {
      const { cash: newCash } = payload;
      const cashesList = state.cashesList.slice();
      cashesList.push(newCash);
      const cashesMap = new Map(state.cashesMap);
      cashesMap.set(newCash.id, newCash);
      return {
        ...state,
        cashesList,
        cashesMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.COMPANY_CASHE_DELETE + types.FULFILLED: {
      const { docId } = payload;
      const cashesList = state.cashesList.filter(
        account => account.company_acct_id !== docId,
      );
      const cashesMap = new Map(state.accountsMap);
      cashesMap.delete(docId);
      return {
        ...state,
        cashesList,
        accountsMap: cashesMap,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};
