import { Map as MapImmutable } from 'immutable';
import equal from 'fast-deep-equal';

import {
  CANCEL_CHANGE,
  FIELD_CHANGE,
  SEND_FULFILLED,
  SEND_PENDING,
  SEND_REJECTED,
  SUCCESS_SHOW,
  SUCCESS_TIMED_OUT,
} from 'actions/settings/requisite/types';

/**
 * Field name of a company data
 * @typedef {string} CompanyField
 */

/**
 * Actual fields values
 * @typedef {Immutable.Map<CompanyField, string>} UpdateSet
 */

/**
 * @typedef {function(): void} AbortCallback
 */

/**
 * @type {Object}
 */
const initialState = {
  /**
   * Changed values by company
   *
   * @type {Immutable.Map<CompanyUuid, Immutable.Map<CompanyField, UpdateSet>>}
   */
  changed: new MapImmutable(),
  /**
   * Currently sending values
   *
   * The values are used to compare with `changed` to know
   * weather the `changed` was changed again while it is sending.
   *
   * @type {Immutable.Map<CompanyUuid, Immutable.Map<CompanyField, UpdateSet>>}
   */
  sending: new MapImmutable(),
  /**
   * Error message by company and field
   *
   * @type {Immutable.Map<CompanyUuid, Immutable.Map<CompanyField, string>>}
   */
  sendFailed: new MapImmutable(),
  /**
   * Success done status by company and field
   *
   * The "success" status of a field is a presence of the field. The value
   * is a callback to abort timeout.
   *
   * @type {Immutable.Map<CompanyUuid, Immutable.Map<CompanyField, AbortCallback>>}
   */
  success: new MapImmutable(),
};

/**
 * Delete field value and cleanup empty company storage
 *
 * @param {Immutable.Map<C,Immutable.Map<F,V>>} map
 * @param {Array} keyPath
 * @return {Immutable.Map<C,Immutable.Map<F,V>>}
 * @template C,F,V
 */
function deleteField(map, keyPath) {
  if (!map.hasIn(keyPath)) {
    return map;
  }

  let result = map.deleteIn(keyPath);
  while (keyPath.length > 1) {
    keyPath = keyPath.slice(0, -1);
    const child = result.getIn(keyPath);
    if (child && child.size > 0) {
      break;
    }
    result = result.deleteIn(keyPath);
  }
  return result;
}

/**
 * Call abort callback for success field status
 *
 * @param {Immutable.Map<CompanyUuid,Immutable.Map<CompanyField,AbortCallback>>} success
 * @param {Array} keyPath
 */
function abortSuccessTimeout(success, keyPath) {
  const abort = success.getIn(keyPath);
  if ('function' === typeof abort) {
    abort();
  }
}

/**
 * Delete field success status and calls its abort callback
 *
 * @param {Immutable.Map<CompanyUuid,Immutable.Map<CompanyField,AbortCallback>>} success
 * @param {Array} keyPath
 * @return {Immutable.Map<CompanyUuid,Immutable.Map<CompanyField,AbortCallback>>}
 */
function deleteSuccessField(success, keyPath) {
  abortSuccessTimeout(success, keyPath);

  return deleteField(success, keyPath);
}

export default (state = initialState, action) => {
  const { type, payload, meta, error } = action;
  switch (type) {
    case FIELD_CHANGE: {
      const { companyUuid, field, update } = payload;
      const { changed } = state;

      const keyPath = [companyUuid, field];

      if (equal(update, changed.getIn(keyPath))) {
        return state;
      }

      return {
        ...state,
        changed: changed.mergeIn(keyPath, update),
      };
    }

    case CANCEL_CHANGE: {
      const { companyUuid, field } = payload;
      const { changed, sending, sendFailed } = state;

      const keyPath = [companyUuid, field];

      return {
        ...state,
        changed: deleteField(changed, keyPath),
        sending: deleteField(sending, keyPath),
        sendFailed: deleteField(sendFailed, keyPath),
      };
    }

    case SEND_PENDING: {
      const { companyUuid, field } = meta;
      const { changed, sending, success } = state;

      const keyPath = [companyUuid, field];

      const value = changed.getIn(keyPath);
      if (undefined === value || equal(value, sending.getIn(keyPath))) {
        return state;
      }

      return {
        ...state,
        sending: sending.setIn(keyPath, value),
        success: deleteSuccessField(success, keyPath),
      };
    }

    case SEND_FULFILLED: {
      const { companyUuid, field } = meta;
      const { changed, sending, sendFailed } = state;

      const keyPath = [companyUuid, field];

      if (!sending.hasIn(keyPath)) {
        return state;
      }

      const isChangedAgain = Boolean(
        changed.hasIn(keyPath) &&
          !equal(changed.getIn(keyPath), sending.getIn(keyPath)),
      );

      return {
        ...state,
        changed: isChangedAgain ? changed : deleteField(changed, keyPath),
        sending: deleteField(sending, keyPath),
        sendFailed: deleteField(sendFailed, keyPath),
      };
    }

    case SEND_REJECTED: {
      const { companyUuid, field } = meta;
      const { sending, sendFailed, success } = state;

      const keyPath = [companyUuid, field];

      if (!sending.hasIn(keyPath)) {
        return state;
      }

      return {
        ...state,
        sending: deleteField(sending, keyPath),
        sendFailed: sendFailed.setIn(
          keyPath,
          error && false === payload.status ? payload.message || null : null,
        ),
        success: deleteSuccessField(success, keyPath),
      };
    }

    case SUCCESS_SHOW: {
      const { companyUuid, field, abort } = payload;
      const { success } = state;

      const keyPath = [companyUuid, field];

      abortSuccessTimeout(success, keyPath);

      return {
        ...state,
        success: success.setIn(keyPath, abort),
      };
    }

    case SUCCESS_TIMED_OUT: {
      const { companyUuid, field } = payload;
      const { success } = state;

      return {
        ...state,
        success: deleteSuccessField(success, [companyUuid, field]),
      };
    }

    default:
      return state;
  }
};
