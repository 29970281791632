/**
 *
 * @param {Object} item DaData.ru single suggestion item
 * @return {{
 *   address: string,
 *   region: string,
 *   area: string,
 *   city: string,
 *   street: string,
 *   house: string,
 *   block: string,
 *   office: string,
 *   postalCode: string,
 *   oktmo: string,
 *   okato: string,
 * }}
 */
export default function convertFromSuggestion(item) {
  const { data } = item;
  // 1 — центр района
  //       (Московская обл, Одинцовский р-н, г Одинцово)
  // 2 — центр региона
  //       (Новосибирская обл, г Новосибирск)
  // 3 — центр района и региона
  //       (Томская обл, г Томск)
  // 4 — центральный район региона
  //       (Тюменская обл, Тюменский р-н)
  const cap = data.capital_marker;
  const city = [data.city_with_type, data.settlement_with_type]
    .filter(Boolean)
    .join(', ');
  const area = city && cap >= 1 && cap <= 4 ? '' : data.area_with_type || '';

  return {
    address: item.value,
    region:
      (city || area) && (2 === cap || 3 === cap)
        ? ''
        : data.region_with_type || '',
    area: area,
    city: city,
    street: data.street_with_type || '',
    house: data.house || '',
    block: data.block || '',
    office: data.flat || '',
    postalCode: data.postal_code || '',
    oktmo: data.oktmo || '',
    okato: data.okato || '',
  };
}
