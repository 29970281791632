import { Action } from 'redux';

export const CHANGE_COLOR_OPTION = 'CUSTOMIZER/CHANGE_COLOR_OPTION';
export const CHANGE_BACKGROUND = 'CUSTOMIZER/CHANGE_BACKGROUND';
export const CHANGE_WIDGETS = 'CUSTOMIZER/CHANGE_WIDGETS_CONTENT_TOP';

export interface ChangeColorAction extends Action {
  type: typeof CHANGE_COLOR_OPTION;
  payload: string;
}

export interface ChangeBackgroundAction extends Action {
  type: typeof CHANGE_BACKGROUND;
  payload: string;
}

export interface ChangeWidgetsAction extends Action {
  type: typeof CHANGE_WIDGETS;
  payload: {
    widgets: string[] | null;
  };
}

export type CustomizerAction =
  | ChangeColorAction
  | ChangeBackgroundAction
  | ChangeWidgetsAction;
