import * as types from 'actions/company/types';

const initialState = {
  uuid: null,
  companyInfo: null,
  isFetching: false,
  didFetched: false,
  isFetchFailed: false,
  didInvalidate: false,
};

export default (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case types.COMPANY_SELECT_UUID: {
      const { uuid } = payload;

      if (!uuid || state.uuid === uuid) {
        return state;
      }

      return {
        ...initialState,
        uuid,
      };
    }

    case types.COMPANY_UNSELECT_UUID: {
      const { uuid } = payload;

      if (!uuid || state.uuid !== uuid) {
        return state;
      }

      return initialState;
    }

    case types.COMPANY_FIELD_SAVED: {
      const { uuid, update } = payload;
      if (state.uuid !== uuid || !state.companyInfo) {
        return state;
      }

      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          ...update,
        },
      };
    }

    case types.COMPANY_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case types.COMPANY_FETCH + types.PENDING:
    case types.COMPANY_FIELD_EDIT + types.PENDING: {
      const { uuid } = meta;

      if (uuid && state.uuid !== uuid) {
        return state;
      }

      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    case types.COMPANY_FETCH + types.FULFILLED: {
      const { uuid } = meta;

      if (uuid && state.uuid !== uuid) {
        return state;
      }

      return {
        ...state,
        companyInfo: payload,
        isFetching: false,
        didFetched: true,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.COMPANY_FETCH + types.REJECTED:
    case types.COMPANY_FIELD_EDIT + types.REJECTED: {
      const { uuid } = meta;

      if (uuid && state.uuid !== uuid) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        isFetchFailed: true,
      };
    }

    case types.COMPANY_FIELD_EDIT + types.FULFILLED: {
      const { uuid } = meta;

      if (uuid && state.uuid !== uuid) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        isFetchFailed: false,
        didInvalidate: false,
      };
    }

    case types.COMPANY_UPDATE_STYLE: {
      const { uuid, styleName, styleInfo } = payload;
      if (state.uuid !== uuid || !state.companyInfo) {
        return state;
      }

      const id = styleInfo ? styleInfo.id : 0;
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          [styleName]: id,
          [styleName + 'Info']: styleInfo,
        },
      };
    }

    default:
      return state;
  }
};
